import React from "react"
import Guest from "../icons/Guest"

const UserAvatar = ({ user, className, ...props }) => {
  if (user) {
    if (user.photoUrl) {
      return (
        <img
          alt={`${user.fullName}'s profile`}
          src={user.photoUrl}
          className={`rounded-full ${className}`}
        />
      )
    }
    return <Placeholder className={`${className} rounded-full`} />
  }
  return <Guest className={className} {...props} />
}

export function Placeholder({ className }) {
  return (
    <svg
      data-test-id="user-avatar-placeholder"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C5.37262 24 0 18.6274 0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12C24 18.6274 18.6274 24 12 24Z"
        fill="var(--gray-100)"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24C5.37262 24 0 18.6274 0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12C24 18.6274 18.6274 24 12 24Z"
          fill="var(--beige-200)"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          opacity="0.4"
          d="M11.641 16.3901C9.61519 16.3901 7.8729 13.9649 7.16497 11.5871C6.81754 11.4788 6.46838 11.0523 6.3024 10.4843C6.08779 9.75021 6.26009 9.0579 6.68732 8.93784C6.73903 8.9233 6.79211 8.91781 6.84584 8.9207C7.06461 5.68085 9.17685 3.1391 11.7502 3.1391C14.3238 3.1391 16.4362 5.68123 16.6547 8.92143C16.7123 8.91709 16.7691 8.92228 16.8245 8.93784C17.2517 9.0579 17.424 9.75021 17.2094 10.4843C17.0357 11.0789 16.6612 11.5185 16.2981 11.6002C15.5258 13.9681 13.6608 16.3901 11.641 16.3901Z"
          fill="var(--black)"
        />
        <path
          opacity="0.6"
          d="M9.4427 15.4283C10.1025 16.0204 10.8502 16.3901 11.6411 16.3901C12.4986 16.3901 13.3282 15.9535 14.0581 15.27V17.7979C14.0581 17.7979 14.3442 20.297 11.7504 20.297C9.15655 20.297 9.4427 17.7979 9.4427 17.7979V15.4283Z"
          fill="var(--black)"
        />
        <path
          opacity="0.8"
          d="M14.058 15.2699V17.7978C14.058 17.7978 14.3442 20.2969 11.7503 20.2969C9.15648 20.2969 9.44264 17.7978 9.44264 17.7978L4.41972 17.7978C3.26449 17.7978 2.38464 18.7079 2.38464 19.8407V25.0377H21.1539V19.8407C21.1539 18.7079 20.1605 17.7978 19.0051 17.7978H14.077V15.2521C14.0706 15.2581 14.0643 15.264 14.058 15.2699Z"
          fill="var(--black)"
        />
      </g>
    </svg>
  )
}

export default UserAvatar
